import axios from 'axios';

export const loadHelpCenterCommand = {
  execute: async (): Promise<any> => {
    const ENDPOINT = 'help-center-topics';
    const POPULATE = 'populate=*';

    const CMSBaseURL = process.env.VSF_CMS_STRAPI_URL;
    const client = axios.create({
      baseURL: CMSBaseURL,
    });
    // eslint-disable-next-line prefer-destructuring
    const VSF_STRAPI_ACCESS_TOKEN = process.env.VSF_STRAPI_ACCESS_TOKEN;
    // eslint-disable-next-line @typescript-eslint/dot-notation
    client.defaults.headers.common['Authorization'] = `Bearer ${VSF_STRAPI_ACCESS_TOKEN}`;

    const url = `${CMSBaseURL}/${ENDPOINT}?${POPULATE}`;

    let items: [];
    try {
      const response = await client.get(url);
      items = response.data.data.map((item) => item.attributes);
    } catch {
      items = [];
    }
    return items;
  },
};
