import axios from 'axios';
import { CmsPage } from '@vue-storefront/magento-api';
import { VsfContext } from '~/composables/context';

export const loadSectionsCommand = {
  execute: async (context: VsfContext, params): Promise<CmsPage> => {
    const ENDPOINT = `${params.identifier}-sections`;

    const CMSBaseURL = process.env.VSF_CMS_STRAPI_URL;
    const client = axios.create({
      baseURL: CMSBaseURL,
    });
    // eslint-disable-next-line prefer-destructuring
    const VSF_STRAPI_ACCESS_TOKEN = process.env.VSF_STRAPI_ACCESS_TOKEN;
    // eslint-disable-next-line @typescript-eslint/dot-notation
    client.defaults.headers.common['Authorization'] = `Bearer ${VSF_STRAPI_ACCESS_TOKEN}`;
    const url = `${CMSBaseURL}/${ENDPOINT}`;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const {
      data: { data },
    } = await client.get(url);
    return data;
  },
};
