import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { SubscriptionStatusesEnum } from '~/modules/GraphQL/types';
import axios, { Method } from 'axios';
import { updateSubscriptionCommand } from './commands/updateSubscriptionCommand';
import type {
  UseNewsletterErrors,
  UseNewsletterInterface,
  UseNewsletterUpdateSubscriptionParams,
  UseNewsletterUpdateSMSSubscriptionParams,
} from './useNewsletter';

/**
 * Allows updating the subscription status of
 * an email in the newsletter.
 *
 * See the {@link UseNewsletterInterface} for a list of methods and values available in this composable.
 */
export function useNewsletter(): UseNewsletterInterface {
  const context = useContext();
  const loading = ref(false);
  const error = ref<UseNewsletterErrors>({
    updateSubscription: null,
  });

  const updateSubscription = async (params: UseNewsletterUpdateSubscriptionParams) => {
    Logger.debug('[Magento]: Update user newsletter subscription', { params });
    let result = SubscriptionStatusesEnum.Unsubscribed;

    try {
      loading.value = true;
      error.value.updateSubscription = null;

      result = await updateSubscriptionCommand.execute(context, params);
    } catch (err) {
      error.value.updateSubscription = err;
      Logger.error('useNewsletter/updateSubscription', err);
    } finally {
      loading.value = false;
    }

    return result;
  };

  const updateSMSSubscription = async (params: UseNewsletterUpdateSMSSubscriptionParams) => {
    // eslint-disable-next-line prefer-destructuring
    const KLAVIYO_API_KEY = process.env.KLAVIYO_API_KEY;
    const KLAVIYO_API_URL = 'https://a.klaviyo.com/api/profile-import/';

    let attributes: any = {};

    if (params.user) {
      attributes = {
        email: params.user.email,
        phone_number: params.telephone,
        first_name: params.user.firstname,
        last_name: params.user.lastname,
      };
      if (params.user?.addresses.length > 0) {
        // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
        const _address = params.user?.addresses[0];
        attributes.location = {
          address1: _address.street.join(' '),
          address2: _address.city,
          city: _address.city,
          country: _address.country_code,
          region: _address.region.region_code,
          zip: _address.postcode,
        };
      }
    } else {
      attributes = {
        phone_number: params.telephone,
      };
    }
    const data = {
      type: 'profile',
      attributes,
    };

    const options = {
      url: KLAVIYO_API_URL,
      method: 'POST' as Method,
      headers: {
        Accept: 'application/json',
        Revision: '2024-05-15',
        'content-type': 'application/json',
        Authorization: `Klaviyo-API-Key ${KLAVIYO_API_KEY}`,
        'Access-Control-Allow-Credentials': true,
      },
      data,
    };
    await axios.post(KLAVIYO_API_URL, options.data);
  };

  return {
    updateSubscription,
    updateSMSSubscription,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useNewsletter';
export default useNewsletter;
